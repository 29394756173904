'use client';
import { useEffect, useMemo } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';

const usePageProtection = () => {
  const { status } = useSession(); // Get session status to determine if the user is authenticated
  const router = useRouter();
  const pathname = usePathname();

  // Paths that allow dynamic route access (e.g., password submission)
  const pathsForDynamicRoutes = useMemo(
    () => ['/auth/submit-new-password'],
    [],
  );

  const isAllowedPath = useMemo(() => {
    // Check if the current path matches any of the allowed dynamic routes
    const isDynamicRouteAllowed = pathsForDynamicRoutes.some((basePath) =>
      pathname.startsWith(basePath),
    );

    // Define static paths that should be publicly accessible without authentication
    const staticPaths = [
      '/auth/register',
      '/auth/login',
      '/auth/request-reset-password',
    ];
    const isStaticRouteAllowed = staticPaths.includes(pathname);

    // Regex pattern to match public invoice paths
    const singleInvoicePathPattern = /^\/workspaces\/[^/]+\/invoices\/[^/]+$/;
    const newInvoicePathPattern =
      /^\/workspaces\/[^/]+\/invoices\/new-invoice$/;

    // Check if the current path matches a public single invoice path
    const isSingleInvoicePath = singleInvoicePathPattern.test(pathname);
    // Check if the current path matches a "new invoice" path (requires authentication)
    const isNewInvoicePath = newInvoicePathPattern.test(pathname);

    // Return true if any of the allowed paths match the current path
    return (
      isDynamicRouteAllowed ||
      isStaticRouteAllowed ||
      (isSingleInvoicePath && !isNewInvoicePath) // Allow public access to single invoice paths
    );
  }, [pathname, pathsForDynamicRoutes]);

  useEffect(() => {
    // Redirect to login if the user is unauthenticated and the path is not allowed
    if (status === 'unauthenticated' && !isAllowedPath) {
      router.push('/auth/login');
    }
  }, [status, pathname, router, isAllowedPath]);

  // Hide UI when session is loading or user is unauthenticated and not on an allowed path
  const shouldHideUI =
    status === 'loading' || (status === 'unauthenticated' && !isAllowedPath);

  return { shouldHideUI };
};

export default usePageProtection;
