'use client';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button } from '../ui/button';
import { AlertCircle, CircleDashed, CirclePlus } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';

const CreateWorkspaceForm = () => {
  const { data: session } = useSession();
  const router = useRouter();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [workspaceUrlSlug, setWorkspaceUrlSlug] = useState('');
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    title: '',
    description: '',
  });

  useEffect(() => {
    if (alert.show) {
      const timer = setTimeout(() => {
        setAlert({ show: false, title: '', description: '' });
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  const handleCheckUsageLimit = async (feature) => {
    const userId = session?.user?.id;

    try {
      const response = await axios.get(
        `/api/check-usage-limits?userId=${userId}&ownerId=${userId}&feature=${feature}`,
      );

      if (response.status === 200) {
        if (response.data.redirect) {
          setAlert({
            show: true,
            title: 'No subscription found!',
            description: response.data.message,
          });
          setTimeout(() => {
            router.push('/billing?tab=plansAndPricing');
          }, 5000);
          setOpenSheet(true);
          setOpenDialogForm(false);
          return false;
        }
        if (response.data.canProceed) {
          return true;
        } else {
          setAlert({
            show: true,
            title: 'Limit Exceeded!',
            description: response.data.message,
          });
          setOpenSheet(true);
          setOpenDialogForm(false);
          setTitle('');
          setWorkspaceUrlSlug('');
          setDescription('');
          return false;
        }
      }
    } catch (error) {
      console.error('Error checking workspace limit:', error);
      setAlert({
        show: true,
        title: 'Error',
        description: 'Failed to check workspace limits',
      });
      setOpenSheet(true);
      setOpenDialogForm(false);
      return false;
    }
  };

  const handleWorkspaceChange = (event) => {
    const inputText = event.target.value;
    setTitle(inputText);
    const formattedText = inputText
      .toLowerCase()
      .trim()
      .replace(/[^a-zA-Z0-9 ]+/g, '')
      .replace(/\s+/g, '-');
    setWorkspaceUrlSlug(formattedText);
  };

  // Creating a Workspace
  const handleCreateWorkspace = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Check user subscription
    const canProceed = await handleCheckUsageLimit('workspace');
    if (!canProceed) {
      setIsLoading(false);
      return;
    }
    const userId = session?.user?.id;
    const ownerName = session?.user?.fullName;
    const ownerEmail = session?.user?.email;
    try {
      const response = await axios.post('/api/create-a-workspace', {
        userId,
        ownerId: userId,
        ownerName,
        ownerEmail,
        title,
        workspaceUrlSlug,
        description,
      });

      // Handle success
      if (response.status === 201) {
        toast.success(
          <div className="text-base font-medium text-muted-foreground">
            You have created a new workspace &quot;
            <span className="text-base font-bold text-rose-500">{title}</span>
            &quot; successfully!
          </div>,
          {
            position: 'bottom-right',
          },
        );

        // Clear form fields and close dialog
        setTitle('');
        setWorkspaceUrlSlug('');
        setDescription('');
        setOpenDialogForm(false);

        router.push(`/workspaces/${workspaceUrlSlug}/business-settings`);
      } else {
        // Handle failure
        console.error('Failed to create workspace');
        toast.error('Failed to create workspace', { position: 'bottom-right' });
      }
    } catch (error) {
      // Handle error
      console.error('Error creating workspace:', error);
      toast.error('Error creating workspace', { position: 'bottom-right' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Dialog open={openDialogForm} onOpenChange={setOpenDialogForm}>
        <DialogTrigger asChild>
          <Button
            variant="outline"
            className="text-base font-bold text-teal-600 hover:text-muted-foreground dark:text-muted-foreground"
          >
            Create A Workspace
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="font-bold text-2xl text-teal-600">
              Create A New Workspace!
            </DialogTitle>
            <DialogDescription className="mt-8 font-semibold text-muted-foreground">
              Make a new workspace/ organization for your business here.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleCreateWorkspace}>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label
                  htmlFor="title"
                  className="text-start text-sm font-semibold text-muted-foreground"
                >
                  Name:
                </Label>
                <Input
                  type="text"
                  id="title"
                  value={title}
                  onChange={handleWorkspaceChange}
                  placeholder="Name of your workspace"
                  required
                  className="col-span-3 text-sm font-semibold text-muted-foreground"
                />
              </div>

              <div className="grid grid-cols-4 items-center gap-4">
                <Label
                  htmlFor="workspaceUrlSlug"
                  className="text-start text-sm font-semibold text-muted-foreground"
                >
                  URL Slug:
                </Label>
                <Input
                  type="text"
                  name="workspaceUrlSlug"
                  id="workspaceUrlSlug"
                  value={workspaceUrlSlug}
                  readOnly
                  className="col-span-3 text-sm font-semibold text-muted-foreground"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label
                  htmlFor="description"
                  className="text-start text-sm font-semibold text-muted-foreground"
                >
                  Description:
                </Label>
                <Textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Description of your workspace (optional)"
                  className="col-span-3 text-sm font-semibold text-muted-foreground"
                />
              </div>
            </div>
            <DialogFooter>
              <Button
                type="submit"
                variant="outline"
                disabled={isLoading}
                className={`font-bold hover:text-teal-600 ${
                  isLoading ? 'text-teal-600' : 'text-muted-foreground'
                } flex items-center justify-center gap-2`}
              >
                {isLoading ? (
                  <CircleDashed className="animate-spin size-6 text-rose-600" />
                ) : (
                  <CirclePlus className="text-teal-600 font-bold size-5" />
                )}

                {isLoading
                  ? 'Creating a Workspace.....'
                  : 'Create a New Workspace'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      {alert.show && (
        <Sheet open={openSheet} onOpenChange={setOpenSheet}>
          <SheetContent
            side="top"
            className="w-full bg-slate-50 dark:bg-background rounded-md"
          >
            <SheetHeader className="sr-only">
              <SheetTitle>Subscription Alert</SheetTitle>
              <SheetDescription>
                This is your subscription status message.
              </SheetDescription>
            </SheetHeader>
            {/* Subscription Alert Message Body */}
            <div className="flex items-center justify-center w-full sm:w-[900px] mx-auto mt-6 sm:mt-0">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle className="text-xl font-bold text-red-500 dark:text-red-200">
                  {alert.title}
                </AlertTitle>
                <AlertDescription className="font-bold text-base text-emerald-600 dark:text-slate-300">
                  {alert.description}
                </AlertDescription>
              </Alert>
            </div>
          </SheetContent>
        </Sheet>
      )}
    </div>
  );
};

export default CreateWorkspaceForm;
